import * as React from "react";
import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { socketEmitter } from "../../api/socket";
import CircularProgressWithLabel from "../CircularProgressWithLabel";

export default function TemplateProgress(props: { id: string }) {
  const [progress, setProgress] = React.useState(100);
  React.useEffect(() => {
    if (!socketEmitter.clientSocket) {
      socketEmitter.connect();
    }

    socketEmitter.clientSocket.on("progress", (value) => {
      setTimeout(() => {
        if (value.id === props.id) setProgress(value.value);
      }, 2000);
    });
  }, [socketEmitter.clientSocket, socketEmitter]);
  return (
    <div style={{paddingRight:10}}>{progress < 99 && <CircularProgressWithLabel value={progress} />}</div>
  );
}
